<template>
  <v-card>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="data"
        sort-by="updated_at"
        :sort-desc="true"
        :search="search"
        class="elevation-1"
        :loading="loading"
        loading-text="Загрузка..."
        @click:row="editItem"
        :items-per-page="20"
        hide-default-footer
        :page.sync="page"
        @page-count="pageCount = $event"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>{{title}}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <div class="flex-grow-1"></div>
            <v-text-field
             v-model="search"
             append-icon="mdi-magnify"
             label="Поиск"
             single-line
             hide-details
             ></v-text-field>
          </v-toolbar>
        </template>
        <!-- <template v-slot:item.action="{ item }">
          <v-icon @click.stop="deleteItem(item)">mdi-delete</v-icon>
        </template> -->
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">Обновить</v-btn>
        </template>
      </v-data-table>
        <div class="text-center pt-2" v-show="pageCount > 1">
          <v-pagination 
            v-model="page" 
            :length="pageCount"
            :total-visible="12"
          >
          </v-pagination>
        </div>
    </v-card-text>

    <v-dialog v-model="dialog" max-width="500px">
      <!-- <template v-slot:activator="{ on }">
        <v-btn
          rounded
          dark
          fixed
          bottom
          right
          x-large
          color="grey darken-2"
          style="z-index: 5;"
          v-if="userIsAdmin"
          v-on="on"
        >
          <v-icon class="mr-5">mdi-plus</v-icon>
          Новое оборудование
        </v-btn>
      </template> -->
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field 
                 v-model="editedItem.slug" 
                 label="Номер" 
                 maxlength="100"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field 
                 v-model="editedItem.name" 
                 label="Наименование" 
                 maxlength="255"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="blue darken-1" text @click="close">Отмена</v-btn>
          <v-btn color="blue darken-1" text @click="save">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ErrorDialog
      v-model="dialogErr"
      title="Ошибка"
      text="В процессе обработки данных произошла ошибка. Проверьте работу сети и повторите попытку позже."
      >
    </ErrorDialog>

  </v-card>
</template>

<script>
  import axios from 'axios'
  import ErrorDialog from '@/components/ErrorDialog'

  export default {
    components: {
      ErrorDialog, axios
    },
    data: () => ({
      title: 'Оборудование',
      modname: 'equipments',
      search: '',
      page: 1,
      pageCount: 0,
      dialog: false,
      loading: true,
      dialogErr: false,
      headers: [
        { text: 'Номер', align: 'left', sortable: true, value: 'slug'},
        { text: 'Наименование', align: 'left', sortable: true, value: 'name'},
        // { text: 'Действия', value: 'action', sortable: false },
      ],
      data: [],
      editedIndex: -1,
      editedItem: {
        slug: '',
        name: '',
      },
      defaultItem: {
        slug: '',
        name: '',
      },
    }),

    created () {
      this.initialize()
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Добавление' : 'Редактирование'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    methods: {
      initialize () {
        this.getAllData()
      },
      getEquipments () { return this.$axios.get(this.modname) },
      getAllData () {
        axios.all([
          this.getEquipments(), 
        ])
          .then(axios.spread((eqs) => {
            this.data = eqs.data
          }))
          .catch(error => {
            this.crudError(error)
          })
          .finally(() => {
            this.loading = false
          })
      },

      crudError (error) {
        // eslint-disable-next-line
        console.error('Data update error', error, this.data)
        this.openError()
      },

      openError () {
        this.dialogErr = !this.dialogErr
      },

      editItem (item) {
        this.editedIndex = this.data.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      // deleteItem (item) {
      //   const index = this.data.indexOf(item)
      //   if (confirm('Уверены, что хотите удалить?')) {
      //     this.$axios.delete(this.modname + '/' + item.id)
      //       .then((r) => {
      //         if (r.data.result === true) {
      //           this.data.splice(index, 1)
      //         } else {
      //           this.crudError(r)
      //         }
      //       })
      //       .catch(error => {
      //         this.crudError(error)
      //       })
      //       .finally(() => {
      //         this.loading = false
      //       })
      //   }
      // },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save () {
        if (this.editedIndex > -1) {
          this.$axios.patch(this.modname + '/' + this.editedItem.id, this.editedItem)
            .then((r) => {
              if (r.data.id) {
                Object.assign(this.data[this.editedIndex], r.data)
              }
            })
            .catch(error => {
              this.crudError(error)
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          this.$axios.post(this.modname, this.editedItem)
            .then((r) => {
              if (r.data.result !== false) {
                this.data.push(r.data)
              }
            })
            .catch(error => {
              this.crudError(error)
            })
            .finally(() => {
              this.loading = false
            })
        }
        this.close()
      },

    },
  }
</script>
